import { anonymousMember, communityMemberModelToViewModel } from '../../utils/member-view-model-helper';
import { IVirtualTerminalPayerViewModel } from '../payer-search/payer-view-model';
import { action, computed, observable, reaction } from 'mobx';
import { IPayerSearchResult } from '../../../components/payer-search-omnibox/payer-search-omnibox';
import {
	CashPaymentMethod,
	existingPaymentMethodFactory,
	ICheckNumber,
	IPaymentEntryModel,
	PaymentMethod,
	PaymentMethodUiType,
	RecordedCheckPaymentMethod,
} from '../payment-entry/payment-entry-model';
import { VirtualTerminalListingStore } from '../../virtual-terminal-listing-store';
import {
	CommonPaymentFieldsModel,
	CustomFieldEditModel,
	CustomFieldType,
	DropDownCustomFieldViewModel,
} from '../../../loggedinweb-generated';
import { existingPaymentMethodsForListing, paymentMethodsForListing } from '../../utils/payment-method-creators';
import { toPaymentMethodType } from '../../utils/payment-method-type-helper';
import {
	AssetType,
	EndType,
	FrequencyCode,
	ScheduleFrequency,
	VirtualTerminalExistingPaymentMethodsViewModel,
	VirtualTerminalListingConfiguration,
	VirtualTerminalPaymentDetailsModel,
} from '../../virtual-terminal-generated';
import { IMemberViewModel } from '../member/member-view-model';
import { StorageHelper } from '../../../helpers/storagehelper';
import { DefaultSettingsViewModel } from '../default-settings/default-settings-view-model';
import {
	CustomFieldViewModel,
	getCustomFieldEditModelMap,
	mergeCustomFieldEditModels,
} from '../../../utils/customFieldsUtils';
import { Models } from '../../../check-deposit/check-deposit-generated';
import { Formatter } from '../../../helpers/formatter';
import { ValidationHelper } from '../../../helpers/validationhelper';

let nextFormKey = 0;

export class VirtualTerminalFormViewModel {
	@observable
	omniboxValue = '';

	@observable
	checkNumber: ICheckNumber = null;

	@observable
	checkReaderState: '' | 'reading' | 'failure' = '';

	@observable
	searchValue = '';

	@observable
	searchResult: IPayerSearchResult = null;

	@observable
	payersAccociatedWithCheckResult: IPayerSearchResult = null;

	@observable
	loadMoreInProgress = false;

	@observable
	openRequested = false;

	@observable
	selectedMember: IMemberViewModel = null;

	@observable
	originalShouldSendEmailNotifications: boolean = null;

	@computed
	get selectedPayer(): IVirtualTerminalPayerViewModel {
		return this.selectedMember && communityMemberModelToViewModel(this.selectedMember);
	}

	@computed
	get formattedTotalAllocated(): string {
		return this.paymentEntry ? `$${Formatter.formatNumberForDisplay(this.paymentEntry.amount)}` : '';
	}

	@observable
	savePaymentInProgress = false;

	@observable
	paymentEntry: IPaymentEntryModel = null;

	@observable
	loadingExistingPaymentMethods = false;

	@observable
	loadingPaymentDetails = false;

	@observable
	amountFocused = false;

	@observable
	formKey = `${nextFormKey++}`;

	@observable splits: Models.CheckSplitEditModel[] = [];

	@observable isSplitGivingEnabled: boolean;

	@observable allAmountsAreValid: boolean;

	@observable splitAmountErrorMessage: string;

	@observable
	processingRemovePaymentMethod: boolean = false;

	@observable
	validationErrors: { [key: string]: string };

	@computed
	get paymentEntryDisabled() {
		return (
			this.loadingPaymentDetails || this.listingStore.loadingListingConfiguration || this.savePaymentInProgress
		);
	}

	@computed
	get isSplit() {
		return this.splits.length > 1;
	}

	@computed
	get paymentEntryBusy() {
		return (
			this.loadingPaymentDetails ||
			this.checkReaderState === 'reading' ||
			(this.listingStore.loadingListingConfiguration && this.paymentEntryVisible)
		);
	}

	@computed
	get paymentSearchDisabled() {
		return this.savePaymentInProgress;
	}

	@computed
	get paymentEntryVisible(): boolean {
		return !!(
			this.paymentEntry &&
			this.paymentEntry.paymentMethod &&
			this.listingStore.selectedListingConfiguration
		);
	}

	@computed
	get currentSearchResult() {
		return this.searchResult ? this.searchResult : this.payersAccociatedWithCheckResult;
	}

	@computed
	get checkReadingMode() {
		return !!this.checkNumber;
	}

	@computed
	get addGiftDisabled() {
		return this.paymentEntryBusy || this.selectedPayer === null;
	}

	@computed
	get selectedMemberIsAnonymous() {
		return this.selectedMember && this.selectedMember.IsAnonymous;
	}

	@computed
	get paymentEntryReferencesValidForTheCurrentMerchant() {
		return (
			this.paymentEntry &&
			this.paymentEntry.CommonPaymentFields &&
			this.paymentEntry.CommonPaymentFields.ReferenceFieldValues.filter(
				(x) => x.Key in this.listingStore.selectedListingCustomFieldsMap
			)
		);
	}

	@computed
	get existingPaymentMethods(): PaymentMethod[] {
		return (
			(this.selectedPayer &&
				this.selectedPayer.existingPaymentMethods &&
				this.selectedPayer.existingPaymentMethods
					.get()
					.PaymentMethods.map((x) => existingPaymentMethodFactory(x))) ||
			[]
		);
	}

	@observable
	newPaymentMethods: PaymentMethod[] = [];

	@computed
	get paymentMethods(): PaymentMethod[] {
		return this.newPaymentMethods.concat(this.existingPaymentMethods);
	}

	@computed
	get hasMoreExistingPaymentMethods() {
		return (
			(this.selectedPayer &&
				this.selectedPayer.existingPaymentMethods &&
				this.selectedPayer.existingPaymentMethods.get().HasMorePages) ||
			false
		);
	}

	@computed
	private get defaultSettings() {
		return this.defaultSettingsViewModel.defaultSettingsModel;
	}

	annualRecurringGivingEnabled: boolean;
	semiAnnualRecurringGivingEnabled: boolean;
	quarterlyRecurringGivingEnabled: boolean;
	shouldShowMemberList: boolean;
	shouldDisplayGiftEntryEditButtons: boolean;
	referenceFields: CustomFieldViewModel[];
	fundField: CustomFieldViewModel;
	firstFundValue?: string;

	constructor(
		public listingStore: VirtualTerminalListingStore,
		private defaultSettingsViewModel: DefaultSettingsViewModel = new DefaultSettingsViewModel(listingStore, null),
		public readonly allowRecurring = true,
		public readonly allowNonCash = true
	) {
		reaction(
			() => this.paymentEntry && this.paymentEntry.paymentMethod && this.paymentEntry.paymentMethod.type,
			() => {
				this.updateSendEmailNotificationForPaymentMethodType();
				this.disableSplitPaymentsForNonCashGift();
			}
		);
		reaction(() => this.listingStore.selectedListingConfiguration, this.applySelectedListingConfiguration, {
			fireImmediately: true,
		});
	}

	@action.bound
	updateSendEmailNotificationForPaymentMethodType() {
		if (
			this.paymentEntry.paymentMethod.type === PaymentMethodUiType.CreditCard ||
			this.paymentEntry.paymentMethod.type === PaymentMethodUiType.ExistingCreditCard
		) {
			this.originalShouldSendEmailNotifications = this.paymentEntry.sendEmailNotifications;
			this.paymentEntry.sendEmailNotifications = true;
		} else {
			if (!this.checkReadingMode) {
				this.paymentEntry.sendEmailNotifications = this.originalShouldSendEmailNotifications;
			}
		}
	}

	@action.bound
	disableSplitPaymentsForNonCashGift() {
		const { selectedListingConfiguration: config } = this.listingStore;
		if (config.SplitGivingEnabled) {
			if (this.paymentEntry.paymentMethod.type == PaymentMethodUiType.NonCash) {
				this.disableSplitGiving();
			} else {
				this.isSplitGivingEnabled = true;
			}
		}
	}

	@action.bound
	disableSplitGiving() {
		this.isSplitGivingEnabled = false;
		if (this.splits.length > 1) {
			this.splits.splice(1);
			this.splits[0].Amount = this.paymentEntry.amount;
		}
	}

	@action.bound
	applySelectedListingConfiguration(config: VirtualTerminalListingConfiguration) {
		if (!config) {
			return;
		}
		this.setNewPaymentMethods();
		this.setPaymentReferenceValues();
		// when listing does not support a payment method, selecte the first or preferred payment method
		if (this.paymentEntry && this.paymentEntry.paymentMethod) {
			if (
				config.SupportedPaymentMethodTypes.indexOf(
					toPaymentMethodType(this.paymentEntry.paymentMethod.type)
				) === -1
			) {
				this.selectPreferredPaymentMethod();
			}
		}

		this.referenceFields = config.CustomFields.filter((x) => x.Type !== Models.CustomFieldType.Fund);
		this.fundField = config.CustomFields.find((x) => x.Type === Models.CustomFieldType.Fund);
		this.firstFundValue = (<DropDownCustomFieldViewModel>this.fundField).Options[0].Value;

		// instantiating a split with the first fund.
		this.splits = [];
		this.addSplit(this.getDefaultFundValue());
		if (config.SplitGivingEnabled) {
			this.isSplitGivingEnabled = true;
		} else {
			this.disableSplitGiving();
		}
	}

	@action.bound
	setNewPaymentMethods() {
		const { selectedListingConfiguration: listingConfiguration } = this.listingStore;

		const listingPaymentMethods = listingConfiguration
			? paymentMethodsForListing(listingConfiguration, this.allowNonCash)
			: [];

		if (this.checkNumber) {
			this.newPaymentMethods = listingPaymentMethods.filter((x) => x.type === PaymentMethodUiType.RecordedCheck);
		} else {
			this.newPaymentMethods = listingPaymentMethods;
		}
	}

	@action.bound
	setPaymentReferenceValues() {
		if (this.paymentEntry) {
			this.paymentEntry.CommonPaymentFields.ReferenceFieldValues = this.getDefaultReferenceValues();
		}
	}

	@action.bound
	handleOmniboxValueChange(value: string) {
		this.omniboxValue = value;

		if (this.checkReaderState === 'failure') {
			this.checkReaderState = '';
		}

		if (!value || !value.trim()) {
			this.selectedMember = null;

			if (!this.checkReadingMode) {
				this.clearSearchResult();

				if (this.paymentEntryVisible) {
					this.resetPaymentDetails();
				}
			}
		}
	}

	@action.bound
	checkReadingStarted() {
		this.selectedMember = null;

		this.checkReaderState = 'reading';
		this.payersAccociatedWithCheckResult = null;
		this.clearSearchResult();
	}

	@action.bound
	checkReadingFailed() {
		this.omniboxValue = '';
		this.checkReaderState = 'failure';
	}

	@action.bound
	setLoadMoreInProgress(inProgress: boolean) {
		this.loadMoreInProgress = inProgress;
	}

	@action.bound
	searchPayersComplete(searchValue: string, searchResult: IPayerSearchResult) {
		this.searchValue = searchValue;
		this.searchResult = searchResult;
		this.loadMoreInProgress = false;
	}

	@action.bound
	searchPayersForCheckComplete(
		check: ICheckNumber,
		searchResult: IPayerSearchResult,
		paymentDetails: VirtualTerminalPaymentDetailsModel
	) {
		this.omniboxValue = '';
		this.checkReaderState = '';
		this.checkNumber = check;
		this.payersAccociatedWithCheckResult = searchResult;
		this.loadMoreInProgress = false;

		if (!searchResult.appendedResults) {
			this.populateDefaultPaymentDetails();
			const recordedCheck = this.newPaymentMethods.find(
				(x) => x.type === PaymentMethodUiType.RecordedCheck
			) as RecordedCheckPaymentMethod;
			const { accountNumber, routingNumber, checkNumber } = this.checkNumber;
			const partiallyMisread = !accountNumber || !routingNumber || !checkNumber;

			this.paymentEntry.paymentMethod = recordedCheck;
			this.paymentEntry.paymentMethod.applyCheckNumber(check);
			this.paymentEntry.paymentMethod.updatePartiallyMisread(partiallyMisread);
			this.paymentEntry.isRecurring = false;

			if (paymentDetails) {
				this.applyPaymentDetailsToReadCheck(paymentDetails);
			} else {
				this.applyDefaultPaymentDetailsToReadCheck();
			}

			if (this.selectedPayer && !this.paymentEntry.amount) {
				this.amountFocused = true;
			}
		}
	}

	@action.bound
	handleMenuStateChange(open: boolean) {
		this.openRequested = open;
	}

	@action.bound
	clearSearchResult() {
		this.searchResult = null;
		this.searchValue = '';
	}

	@action.bound
	selectMemberMatch(searchResult: IMemberViewModel[], matchedId: number) {
		if (matchedId === null) {
			return;
		}
		[this.selectedMember] = searchResult.filter((x) => x.CommunityMemberId === matchedId);
	}

	@action.bound
	updateSelectedMember(member: IMemberViewModel) {
		this.selectedMember = member;

		this.clearSearchResult();

		// focus the amount field when there's a payer selected and no amount entered
		if (member && this.paymentEntry && this.paymentEntryVisible && !this.paymentEntry.amount) {
			this.amountFocused = true;
		}
	}

	@action.bound
	editSelectedMember(member: IMemberViewModel) {
		const existingPaymentMethods = { ...this.selectedPayer.existingPaymentMethods.get() };
		this.selectedMember = member;
		this.selectedPayer.existingPaymentMethods.set(existingPaymentMethods);

		if (this.paymentEntry && this.paymentEntryVisible && !this.paymentEntry.amount) {
			this.amountFocused = true;
		}
	}

	@action.bound
	loadExistingPaymentMethodsStarted() {
		this.loadingExistingPaymentMethods = true;
	}

	@action.bound
	loadExistingPaymentMethodsFinished(existingPaymentMethods: VirtualTerminalExistingPaymentMethodsViewModel) {
		this.loadingExistingPaymentMethods = false;
		if (this.selectedPayer) {
			const { selectedListingConfiguration } = this.listingStore;
			existingPaymentMethods.PaymentMethods = existingPaymentMethodsForListing(
				existingPaymentMethods.PaymentMethods,
				selectedListingConfiguration
			);
			this.selectedPayer.existingPaymentMethods.set(existingPaymentMethods);
		}
	}

	@action.bound
	loadExistingPaymentMethodsCancelled() {
		this.loadingExistingPaymentMethods = false;
	}

	@action.bound
	loadPaymentDetailsStarted(checkMode: boolean) {
		this.loadingPaymentDetails = true;
	}

	@action.bound
	loadPaymentDetailsFinished(paymentDetails: VirtualTerminalPaymentDetailsModel) {
		this.loadingPaymentDetails = false;

		const hasPaymentDetailsAndInCheckReadingMode = paymentDetails && this.checkReadingMode;

		if (hasPaymentDetailsAndInCheckReadingMode) {
			this.applyPaymentDetailsToReadCheck(paymentDetails);
		} else if (paymentDetails) {
			this.applyPaymentDetails(paymentDetails);
		} else if (this.checkReadingMode) {
			this.applyDefaultPaymentDetailsToReadCheck();
		} else {
			this.populateDefaultPaymentDetails();
		}

		if (!this.paymentEntry.paymentMethod) {
			this.selectPreferredPaymentMethod();
		}

		if (!this.paymentEntry.amount) {
			this.amountFocused = true;
		}
	}

	@action.bound
	loadPaymentDetailsCancelled() {
		this.loadingPaymentDetails = false;
	}

	@action.bound
	handleAmountChange(value: number) {
		this.paymentEntry.amount = value;
		if (this.splits.length === 1) {
			this.splits[0].Amount = value;
		}
	}

	@action.bound
	setAmountFocused(focused: boolean = true) {
		this.amountFocused = focused;
	}

	@action.bound
	setAnonymousPayer() {
		this.updateSelectedMember(anonymousMember);
		this.populateDefaultPaymentDetails();
		const cashPaymentMethod = this.newPaymentMethods.find(
			(x) => x.type === PaymentMethodUiType.Cash
		) as CashPaymentMethod;
		this.paymentEntry.paymentMethod = cashPaymentMethod;
	}

	@action.bound
	updateReferenceFieldValue(field: CustomFieldEditModel) {
		const values = this.paymentEntry.CommonPaymentFields.ReferenceFieldValues;
		const current = values.find((x) => x.Key === field.Key);

		if (current) {
			values.splice(values.indexOf(current), 1, field);
		} else {
			values.push(field);
		}
	}

	@action.bound
	updateNotes(value: string) {
		this.paymentEntry.CommonPaymentFields.Notes = value;
	}

	@action.bound
	updateGivenOn(date: Date) {
		this.paymentEntry.CommonPaymentFields.GivenOn = date;
		StorageHelper.setGivenOn(date);
	}

	@action.bound
	updatePaymentMethod(key: string) {
		const paymentMethod = this.paymentMethods.find((x) => x.key === key);
		const paymentMethodType = paymentMethod.type;

		if (
			this.paymentEntry.isRecurring &&
			!(paymentMethodType === PaymentMethodUiType.ACH || paymentMethodType === PaymentMethodUiType.CreditCard)
		) {
			this.updateIsRecurring(false);
			this.updateRecurringFrequency(`${FrequencyCode.Monthly}`);
			this.updateRecurringStartDate(this.listingStore.selectedListingConfiguration.DefaultRecurringStartDate);
			this.updateRecurringEndType(`${EndType.Never}`);
		}

		this.paymentEntry.paymentMethod = paymentMethod;
	}

	@action.bound
	updateYourId(value: string) {
		this.paymentEntry.yourId = value;
	}

	@action.bound
	updateSendEmailNotifications(sendEmailNotifications: boolean) {
		this.paymentEntry.sendEmailNotifications = sendEmailNotifications;
	}

	@action.bound
	updateIsRecurring(isRecurring: boolean) {
		this.paymentEntry.isRecurring = this.allowRecurring && isRecurring;
	}

	@action.bound
	updateRecurringFrequency(frequency: string) {
		this.paymentEntry.recurringSchedule.FrequencyCode = parseInt(frequency);
	}

	@action.bound
	updateRecurringStartDate(startDate: Date) {
		const { EndDate } = this.paymentEntry.recurringSchedule;
		this.paymentEntry.recurringSchedule.WhenNextPaymentDue = startDate;

		if (EndDate && startDate > EndDate) {
			this.updateRecurringEndDate(null);
		}
	}

	@action.bound
	updateRecurringEndType(endType: string) {
		const { recurringSchedule } = this.paymentEntry;
		const { EndDate, EndOccurrences } = recurringSchedule;
		const endTypeValue = parseInt(endType);

		switch (endTypeValue) {
			case EndType.Never:
				if (EndOccurrences) {
					this.updateRecurringEndOccurrences('');
				}
				if (EndDate) {
					this.updateRecurringEndDate(null);
				}
				break;
			case EndType.Date:
				if (EndOccurrences) {
					this.updateRecurringEndOccurrences('');
				}
				break;
			case EndType.Occurrences:
				if (EndDate) {
					this.updateRecurringEndDate(null);
				}
				break;
		}

		recurringSchedule.EndType = endTypeValue;
	}

	@action.bound
	updateRecurringEndDate(endDate: Date) {
		this.paymentEntry.recurringSchedule.EndDate = endDate;
	}

	@action.bound
	updateRecurringEndOccurrences(endOccurrences: string) {
		const value = parseInt(endOccurrences);
		let endOccurrencesValue;

		if (isNaN(value)) {
			endOccurrencesValue = '';
		} else if (value < 1) {
			endOccurrencesValue = 1;
		} else {
			endOccurrencesValue = value;
		}

		this.paymentEntry.recurringSchedule.EndOccurrences = endOccurrencesValue;
	}

	@action.bound
	savePaymentStarted() {
		this.savePaymentInProgress = true;
	}

	@action.bound
	savePaymentFinished() {
		this.savePaymentInProgress = false;
	}

	@action.bound
	removePaymentMethod(paymentMethod: PaymentMethod) {
		if (this.paymentEntry.paymentMethod === paymentMethod) {
			this.selectPreferredPaymentMethod();
		}

		let existingPaymentMethodsViewModel = this.selectedPayer.existingPaymentMethods.get();
		const paymentMethods = existingPaymentMethodsViewModel.PaymentMethods.filter(
			(x) => `${x.PaymentMethodIndex}` !== paymentMethod.key
		);

		this.selectedPayer.existingPaymentMethods.set({
			PaymentMethods: paymentMethods,
			HasMorePages: existingPaymentMethodsViewModel.HasMorePages,
		});
	}

	@action.bound
	removePaymentMethodStarted() {
		this.processingRemovePaymentMethod = true;
	}

	@action.bound
	removePaymentMethodFinished() {
		this.processingRemovePaymentMethod = false;
	}

	@action.bound
	updateValidationErrors(validationErrors: { [key: string]: string }) {
		this.validationErrors = validationErrors;
	}

	@action.bound
	resetForm() {
		this.formKey = `${nextFormKey++}`;
		this.omniboxValue = '';
		this.checkReaderState = '';
		this.checkNumber = null;
		this.payersAccociatedWithCheckResult = null;
		this.selectedMember = null;
		this.clearSearchResult();

		this.resetPaymentDetails();
	}

	@action.bound
	resetPaymentDetails() {
		this.populateDefaultPaymentDetails();
		this.setNewPaymentMethods();
		this.selectPreferredPaymentMethod();
		this.amountFocused = false;
	}

	@action.bound
	setDescriptionForDonor(value: string) {
		this.paymentEntry.descriptionForDonor = value;
	}

	@action.bound
	setDescriptionForMerchant(value: string) {
		this.paymentEntry.descriptionForMerchant = value;
	}

	@action.bound
	setAssetType(value: AssetType) {
		this.paymentEntry.assetType = value;
	}

	@action.bound
	private populateDefaultPaymentDetails() {
		this.originalShouldSendEmailNotifications = true;
		const { DefaultPaymentMethodType } = this.defaultSettings;
		this.paymentEntry = {
			amount: null,
			CommonPaymentFields: this.getDefaultCommonPaymentFields(),
			paymentMethod: this.paymentMethods.find((x) => toPaymentMethodType(x.type) === DefaultPaymentMethodType),
			paymentMethodIndex: null,
			isRecurring: false,
			yourId: null,
			sendEmailNotifications: true,
			recurringSchedule: this.getDefaultRecurringSchedule(),
		};

		this.splits = [];
		this.addSplit(this.getDefaultFundValue());

	}

	@action.bound
	private getDefaultCommonPaymentFields() {
		const { DefaultNotes, DefaultGivenOn } = this.defaultSettings;
		return <CommonPaymentFieldsModel>{
			GivenOn: DefaultGivenOn || StorageHelper.getGivenOn(),
			Notes: DefaultNotes || '',
			ReferenceFieldValues: this.getDefaultReferenceValues(),
		};
	}

	@action.bound
	private getDefaultRecurringSchedule() {
		return <ScheduleFrequency>{
			FrequencyCode: FrequencyCode.Monthly,
			WhenNextPaymentDue: this.listingStore.selectedListingConfiguration.DefaultRecurringStartDate,
			EndType: EndType.Never,
			EndOccurrences: null,
			EndDate: null,
		};
	}

	@action.bound
	private applyPaymentDetailsToReadCheck(paymentDetails: VirtualTerminalPaymentDetailsModel) {
		if (paymentDetails.Amount !== null) {
			this.paymentEntry.amount = paymentDetails.Amount;
		}

		this.paymentEntry.yourId = paymentDetails.YourId;
		this.paymentEntry.sendEmailNotifications = paymentDetails.WantsTransactionNotifications;
		this.paymentEntry.CommonPaymentFields =
			this.getCommonFieldsFromPaymentDetailsAndDefaultSettings(paymentDetails);
	}

	@action.bound
	private applyPaymentDetails(paymentDetails: VirtualTerminalPaymentDetailsModel) {
		this.originalShouldSendEmailNotifications = paymentDetails.WantsTransactionNotifications;
		this.paymentEntry = {
			amount: paymentDetails.Amount !== null ? paymentDetails.Amount : null,
			CommonPaymentFields: this.getCommonFieldsFromPaymentDetailsAndDefaultSettings(paymentDetails),
			paymentMethod: !paymentDetails ? null : this.getPaymentMethod(paymentDetails),
			paymentMethodIndex: paymentDetails.PaymentMethodIndex,
			yourId: paymentDetails.YourId,
			isRecurring: false,
			sendEmailNotifications: paymentDetails.WantsTransactionNotifications,
			recurringSchedule: this.getDefaultRecurringSchedule(),
		};

		if (this.splits.length > 1) {
			this.splits = this.splits.slice(0, 1);
		}

		this.splits[0].Amount = this.paymentEntry.amount;
		this.splits[0].Fund.Value = this.getDefaultFundValue(this.paymentEntry);

		if (this.paymentEntry.paymentMethod !== null) {
			this.updateSendEmailNotificationForPaymentMethodType();
		}
	}

	@action.bound
	private applyDefaultPaymentDetailsToReadCheck() {
		this.paymentEntry.amount = null;
		this.paymentEntry.yourId = null;
		this.paymentEntry.CommonPaymentFields = this.getDefaultCommonPaymentFields();
		this.paymentEntry.sendEmailNotifications = true;
	}

	@action.bound
	private selectPreferredPaymentMethod() {
		this.paymentEntry.paymentMethod = this.getPreferredPaymentMethod();
	}

	@action.bound
	private getPreferredPaymentMethod(): PaymentMethod {
		const { selectedListingConfiguration } = this.listingStore;
		const preferredPaymentMethod =
			this.newPaymentMethods.find(
				(x) => toPaymentMethodType(x.type) === selectedListingConfiguration.PreferredPaymentMethodType
			) || this.newPaymentMethods[0];
		return preferredPaymentMethod;
	}

	@action.bound
	private getPaymentMethod(paymentDetails: VirtualTerminalPaymentDetailsModel) {
		const { PaymentReference, PaymentMethodIndex } = paymentDetails;
		const { DefaultPaymentMethodType } = this.defaultSettings;

		const paymentMethod = DefaultPaymentMethodType
			? this.getPaymentMethodUsingDefault(PaymentMethodIndex)
			: this.getPaymentMethodFromDetails(paymentDetails);

		if (!paymentMethod) {
			return this.getPreferredPaymentMethod();
		}

		if (
			paymentMethod.type === PaymentMethodUiType.RecordedACH ||
			paymentMethod.type === PaymentMethodUiType.RecordedCreditCard
		) {
			(paymentMethod as any).updatePaymentReference(PaymentReference);
		}

		return paymentMethod;
	}

	@action.bound
	private getPaymentMethodUsingDefault(paymentMethodIndex: number): PaymentMethod {
		const { DefaultPaymentMethodType } = this.defaultSettings;

		if (paymentMethodIndex !== null) {
			const existingPaymentMethod = this.tryGetExistingPaymentMethodUsingDefault(paymentMethodIndex);
			if (existingPaymentMethod) {
				return existingPaymentMethod;
			}
		}

		return this.newPaymentMethods.find((x) => toPaymentMethodType(x.type) === DefaultPaymentMethodType);
	}

	@action.bound
	private tryGetExistingPaymentMethodUsingDefault(paymentMethodIndex: number): PaymentMethod {
		const { DefaultPaymentMethodType } = this.defaultSettings;
		return this.existingPaymentMethods.find(
			(x) => x.key === `${paymentMethodIndex}` && toPaymentMethodType(x.type) === DefaultPaymentMethodType
		);
	}

	@action.bound
	private getPaymentMethodFromDetails(paymentDetails: VirtualTerminalPaymentDetailsModel): PaymentMethod {
		if (paymentDetails.PaymentMethodIndex !== null) {
			return this.existingPaymentMethods.find((x) => x.key === `${paymentDetails.PaymentMethodIndex}`);
		}
		return this.newPaymentMethods.find((x) => toPaymentMethodType(x.type) === paymentDetails.PaymentMethodType);
	}

	@action.bound
	private getCommonFieldsFromPaymentDetailsAndDefaultSettings(
		paymentDetails: VirtualTerminalPaymentDetailsModel
	): CommonPaymentFieldsModel {
		const { Notes: paymentNotes, ReferenceFieldValues: paymentReferenceFieldValues } = paymentDetails;
		const { DefaultNotes, DefaultGivenOn, DefaultReferenceFields } = this.defaultSettings;

		return {
			Notes: DefaultNotes || paymentNotes,
			GivenOn: DefaultGivenOn || StorageHelper.getGivenOn(),
			ReferenceFieldValues: paymentReferenceFieldValues
				? mergeCustomFieldEditModels(
						getCustomFieldEditModelMap(paymentReferenceFieldValues),
						DefaultReferenceFields
				  )
				: this.getDefaultReferenceValues(),
		};
	}

	@action.bound
	private getDefaultReferenceValues(): CustomFieldEditModel[] {
		const { DefaultReferenceFields } = this.defaultSettings;
		return mergeCustomFieldEditModels(
			this.listingStore.selectedListingCustomFieldDefaultValuesMap,
			DefaultReferenceFields
		);
	}

	@action.bound
	handleAddFund() {
		const selectedFunds = this.splits.map(split => split.Fund.Value);
		const nextAvailableFund = (<DropDownCustomFieldViewModel>this.fundField).Options.find(fund => selectedFunds.indexOf(fund.Value) === -1);
		this.addSplit(nextAvailableFund ? nextAvailableFund.Value : '');
	}

	@action.bound
	addSplit(fundValue: string) {
		this.splits.push({
			Fund: {
				Key: this.fundField.Key,
				Value: fundValue,
				Type: CustomFieldType.DropDown,
			},
			Amount: null,
			AmountValidationErrorMessage: null,
			FundValidationErrorMessage: null,
		});
		this.validateSplits();
	}

	@action.bound
	handleRemoveFund(index: number) {
		this.splits.splice(index, 1);
		this.validateSplits();
	}

	@action.bound
	handleFundChanged(fundValue: string, index: number) {
		this.splits[index].Fund.Value = fundValue;
		this.validateFunds();
	}

	@action.bound
	handleAmountChanged(amountValue: number, index: number) {
		this.splits[index].Amount = amountValue;
		this.validateAmounts();
	}

	@action.bound
	handleAmountBlurred() {
		this.validateAmounts();
	}

	private validateSplits = (): void => {
		this.validateAmounts();
		this.validateFunds();
	};

	@action.bound
	private validateAmounts() {
		if (!this.paymentEntry) {
			return;
		}

		this.paymentEntry.amount = this.splits.reduce((sum, split) => sum + split.Amount, 0);
		this.paymentEntry.amount = this.paymentEntry.amount == 0 ? null : this.paymentEntry.amount;

		const config = this.listingStore.selectedListingConfiguration;

		this.splits.forEach((item) => {
			if (item.Amount <= 0) {
				item.AmountValidationErrorMessage = 'Please enter an amount';
			} else if (item.Amount < config.PaymentMin) {
				item.AmountValidationErrorMessage = `Your amount should be above $${Formatter.formatNumberForDisplay(config.PaymentMin)}`;
			} else {
				item.AmountValidationErrorMessage = null;
			}
		});

		const uiPaymentMethodType = this.paymentEntry && this.paymentEntry.paymentMethod && this.paymentEntry.paymentMethod.type
			? this.paymentEntry.paymentMethod.type
			: null;
		const usePaymentMax = uiPaymentMethodType == null || ValidationHelper.paymentMethodTypeHasMaxAmountLimit(uiPaymentMethodType);

		if (usePaymentMax && this.paymentEntry.amount > config.PaymentMax) {
			this.allAmountsAreValid = false;
			this.splitAmountErrorMessage = `Your total amount should be below $${Formatter.formatNumberForDisplay(config.PaymentMax)}`;
		} else {
			this.allAmountsAreValid = true;
			this.splitAmountErrorMessage = null;
		}
	}

	@action.bound
	private validateFunds() {
		// Count occurrences of each FundKey, ignoring empty strings
		const frequencyCount = this.splits.reduce((ret, cur) => {
			if (cur.Fund.Value !== '') {
				ret[cur.Fund.Value] = (ret[cur.Fund.Value] || 0) + 1;
			}
			return ret;
		}, {});

		// go through funds and add error message if value in duplicates
		this.splits.forEach((split) => {
			split.FundValidationErrorMessage =
				(frequencyCount[split.Fund.Value] || 0) > 1 ? 'Please ensure all funds are unique.' : null;
		});
	}

	@action.bound
	private getDefaultFundValue(paymentEntry: IPaymentEntryModel | undefined = undefined) {
		if (paymentEntry) {
			const fundOnPaymentEntry = paymentEntry.CommonPaymentFields.ReferenceFieldValues.find(x => x.Key == this.fundField.Key);
			if (fundOnPaymentEntry && fundOnPaymentEntry.Value) {
				return fundOnPaymentEntry.Value;
			}
		}

		const defaultFund = this.getDefaultReferenceValues().find(x => x.Key == this.fundField.Key);
		return defaultFund && defaultFund.Value ? defaultFund.Value : this.firstFundValue;
	}
}
