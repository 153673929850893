

// ReSharper disable InconsistentNaming
// ReSharper disable StringLiteralWrongQuotes
/* tslint:disable:no-use-before-declare quotemark max-line-length */
import { IApiAction } from '../utils/data-service';
export interface EmbeddedFormViewModelRequest {
	MerchantHandle: string;
	EmbeddedRedirectType: EmbeddedRedirectType;
	EmbeddedRedirectButtonUrl: string;
	EmbeddedRedirectButtonText: string;
}
export enum EmbeddedRedirectType {
	Unknown = 0, None = 1, Manual = 2,
}
export interface EmbeddedFormViewModelResult {
	WebGivingUrl: string;
	PrefersRecurring: boolean;
	MaxPaymentAmount: number;
	MinPaymentAmount: number;
	Color: string;
	CryptoLink: string;
	StockLink: string;
	OneTimeFutureGiftEnabled: boolean;
	PushpayIconUrl: string;
	WebGivingSignedConfig: string;
	EmbeddedSourceKey: string;
	SignedConfigQueryName: string;
	SourceQueryName: string;
	DefaultFundKey: string;
	PaymentLabel: PaymentLabel;
	PayerSpecifyRecurringEndDate: boolean;
	MerchantFundList: MerchantFundInfo[];
	BeaconUrl: string;
	ScriptSourceUrl: string;
	Handle: string;
}
export interface MerchantFundInfo {
	FundName: string;
	FundKey: string;
}
export interface PaymentLabel {
	NounSentenceCase: string;
	NounPluralSentenceCase: string;
	VerbSentenceCase: string;
	VerbAppliedSentenceCase: string;
	NounLowerCase: string;
	NounPluralLowerCase: string;
	VerbLowerCase: string;
	VerbAppliedLowerCase: string;
	VerbPresentTenseSentenceCase: string;
	VerbPresentTenseLowerCase: string;
	VerbPastTenseSentenceCase: string;
	VerbPastTenseLowerCase: string;
	PayerSentenceCase: string;
	PayerLowerCase: string;
	PayerPluralSentenceCase: string;
	PayerPluralLowerCase: string;
	ActionSentenceCase: string;
	ActionLowerCase: string;
	ActionPluralSentenceCase: string;
	ActionPluralLowerCase: string;
	NonCashNounSentenceCase: string;
	NonCashNounLowerCase: string;
	NonCashPluralSentenceCase: string;
	NonCashPluralLowerCase: string;
	TextGivingFeatureName: string;
	GiftEntryFeatureName: string;
}
export interface PreconfiguredGivingLinkGenerationRequest {
	MerchantHandle: string;
	FundName: string;
	FundVisibility: FundVisibility;
	Amount: number;
	IsFixedAmount: boolean;
	RecurringVisibility: boolean;
	GiftType: GiftType;
	FrequencyCode: FrequencyCode;
	StartDate: Date;
	RecurringEndType: RecurringEndType;
	EndDate: Date;
	RecurringEndOccurrences: number;
	RedirectUrl: string;
	Note: string;
	Language: Language;
	DisplayQrCodeLogo: boolean;
}
export enum RecurringEndType {
	Never = 0, Date = 1, Occurrences = 2,
}
export enum FrequencyCode {
	Other = 0, Weekly = 1, Fortnightly = 2, Monthly = 3, FirstAndFifteenth = 4, OneMinute = 5, FiveMinutes = 6, FourHours = 7, Yearly = 8, SemiYearly = 9, Quarterly = 10,
}
export enum GiftType {
	Default = 0, Single = 1, Recurring = 2,
}
export enum FundVisibility {
	Default = 0, Show = 1, Hide = 2, Lock = 3,
}
export interface PreconfiguredGivingLinkGenerationResult {
	QrCodeGenerationUrl: string;
	ShortLink: string;
}
export interface Language {
	Name: string;
	TwoLetterIsoCode: string;
}
export interface FundInfoViewModel {
	FundName: string;
	FundKey: string;
	ForListing: boolean;
}
export interface PreconfiguredGivingLinkViewModel {
	GeneratedUrl: string;
	MerchantListings: PreconfiguredGivingLinkMerchantListing[];
	PayerSpecifiedRecurringEndDateEnabled: boolean;
	PaymentLabel: PaymentLabel;
	IsEntitledToLanguageSelector: boolean;
	AvailableRecurringFrequencies: FrequencyCode[];
	PreconfiguredGivingLinkHelpUrl: string;
	EmbeddedWidgetHelpUrl: string;
	EmbeddedGivingFormGenerationFlag: boolean;
}
export interface PreconfiguredGivingLinkMerchantListing {
	ListingId: number;
	ListingName: string;
	ListingHandle: string;
	ListingDateTime: Date;
	ListingGiftRecurringByDefault: boolean;
	ListingIsActive: boolean;
}
export const PreconfiguredGivingLinksApiConfig = {
	defaultBaseUrl: () => (<RegExpMatchArray>location.pathname.match(new RegExp('/pushpay/portal/\\d+', 'i')))[0],
	actions: {
		getAllFundsForCurrentListing: <IApiAction<{listingId: number}>>{
			url: (data) => `/preconfigured-giving-links/get-all-funds-for-current-listing`,
		},
		generateUrlWithQrCodeLink: <IApiAction<{request: PreconfiguredGivingLinkGenerationRequest}, PreconfiguredGivingLinkGenerationResult>>{
			url: (data) => `/preconfigured-giving-links/generate-url`,
		},
		getEmbeddedFormInfo: <IApiAction<{request: EmbeddedFormViewModelRequest}, EmbeddedFormViewModelResult>>{
			url: (data) => `/preconfigured-giving-links/generate-embedded-form`,
		},
	},
	urls: {
	},
};
/* tslint:enable:no-use-before-declare quotemark max-line-length */
// ReSharper restore InconsistentNaming
// ReSharper restore StringLiteralWrongQuotes
// ReSharper disable InconsistentNaming
// ReSharper disable StringLiteralWrongQuotes
/* tslint:disable:no-use-before-declare quotemark max-line-length */
export namespace ModelMetadata {
	export class MetadataArrayOf<T> {
		metadata: T;

		constructor(metadata: T) {
			this.metadata = metadata;
		}

		at(index: number): T {
			return this.metadata;
		}
	}

	export interface IPropertyMetadata {
		propertyName: string;
		displayName?: string;
		placeholder?: string;
		validationRules?: { [rule: string]: any };
		modelMetadata?: { [name: string] :IPropertyMetadata};
	}

	export let PreconfiguredGivingLinkGenerationRequest = {
		MerchantHandle: {
			propertyName: "MerchantHandle",
			displayName: "Merchant Handle",
			validationRules: {
				required: {
					errorMessage: "Please choose an organization with a valid merchant listing.",
				},
			},
		},
		FundName: {
			propertyName: "FundName",
			displayName: "Fund Name",
		},
		FundVisibility: {
			propertyName: "FundVisibility",
			displayName: "Fund Visibility",
			validationRules: {
				required: {
					errorMessage: "The Fund Visibility field is required.",
				},
			},
		},
		Amount: {
			propertyName: "Amount",
			displayName: "Amount",
			validationRules: {
				number: {
					errorMessage: "The field Amount must be a number.",
				},
			},
		},
		IsFixedAmount: {
			propertyName: "IsFixedAmount",
			displayName: "Set Fixed Amount",
			validationRules: {
				required: {
					errorMessage: "The Set Fixed Amount field is required.",
				},
			},
		},
		RecurringVisibility: {
			propertyName: "RecurringVisibility",
			displayName: "Recurring Visibility",
			validationRules: {
				required: {
					errorMessage: "The Recurring Visibility field is required.",
				},
			},
		},
		GiftType: {
			propertyName: "GiftType",
			displayName: "Gift Type",
			validationRules: {
				required: {
					errorMessage: "The Gift Type field is required.",
				},
			},
		},
		FrequencyCode: {
			propertyName: "FrequencyCode",
			displayName: "Recurring Type",
			validationRules: {
				required: {
					errorMessage: "The Recurring Type field is required.",
				},
			},
		},
		StartDate: {
			propertyName: "StartDate",
			displayName: "Start Date",
			validationRules: {
				date: {
					errorMessage: "The field Start Date must be a date.",
				},
				required: {
					errorMessage: "The Start Date field is required.",
				},
			},
		},
		RecurringEndType: {
			propertyName: "RecurringEndType",
			displayName: "Recurring End Type",
			validationRules: {
				required: {
					errorMessage: "The Recurring End Type field is required.",
				},
			},
		},
		EndDate: {
			propertyName: "EndDate",
			displayName: "End Date",
			validationRules: {
				date: {
					errorMessage: "The field End Date must be a date.",
				},
				required: {
					errorMessage: "The End Date field is required.",
				},
			},
		},
		RecurringEndOccurrences: {
			propertyName: "RecurringEndOccurrences",
			displayName: "Occurrences",
			validationRules: {
				number: {
					errorMessage: "The field Occurrences must be a number.",
				},
				range: {
					errorMessage: "Please enter a number between 1 and 1000",
					parameters: {
						max: 1000,
						min: 1,
					},
				},
				required: {
					errorMessage: "The Occurrences field is required.",
				},
			},
		},
		RedirectUrl: {
			propertyName: "RedirectUrl",
			displayName: "Redirect Url",
		},
		Note: {
			propertyName: "Note",
			displayName: "Note",
		},
		Language: {
			propertyName: "Language",
			displayName: "Language",
		},
		DisplayQrCodeLogo: {
			propertyName: "DisplayQrCodeLogo",
			displayName: "QR Code Logo Display",
			validationRules: {
				required: {
					errorMessage: "The QR Code Logo Display field is required.",
				},
			},
		},
	};
	export let EmbeddedFormViewModelRequest = {
		MerchantHandle: {
			propertyName: "MerchantHandle",
		},
		EmbeddedRedirectType: {
			propertyName: "EmbeddedRedirectType",
			validationRules: {
				required: {
					errorMessage: "The EmbeddedRedirectType field is required.",
				},
			},
		},
		EmbeddedRedirectButtonUrl: {
			propertyName: "EmbeddedRedirectButtonUrl",
			validationRules: {
				regex: {
					errorMessage: "Please enter a valid URL that starts with 'http://' or 'https://' e.g. https://www.example.com or http://www.example.com",
					parameters: {
						pattern: "^(https?)://.*$",
					},
				},
				required: {
					errorMessage: "A redirect url is required",
				},
			},
		},
		EmbeddedRedirectButtonText: {
			propertyName: "EmbeddedRedirectButtonText",
			validationRules: {
				length: {
					errorMessage: "Please enter text with 20 characters or fewer",
					parameters: {
						max: 20,
						min: 1,
					},
				},
				required: {
					errorMessage: "Redirect button text is required when redirecting",
				},
			},
		},
	};
}
/* tslint:enable:no-use-before-declare quotemark max-line-length */
// ReSharper restore InconsistentNaming
// ReSharper restore StringLiteralWrongQuotes
// ReSharper disable InconsistentNaming
// ReSharper disable StringLiteralWrongQuotes
/* tslint:disable:no-use-before-declare quotemark max-line-length */
export const LanguageOptions: Record<string, Language> = {
	en: {"Name":"English","TwoLetterIsoCode":"en"},
	es: {"Name":"Spanish","TwoLetterIsoCode":"es"}};
/* tslint:enable:no-use-before-declare quotemark max-line-length */
// ReSharper restore InconsistentNaming
// ReSharper restore StringLiteralWrongQuotes
export const PCGLQueryKeys = {
	Recurrence: 'r',
	UserFirstName: 'ufn',
	UserLastName: 'uln',
	UserEmail: 'ue',
	UserPhone: 'up',
	GiftDate: 'gd',
	RecurringStartDate: 'rsd',
	RecurringEndType: 'ret',
	RecurringEndDate: 'red',
	RecurringEndOccurence: 'reo',
	RecurringSelectorIsVisible: 'rcv',
	RedirectUrl: 'ru',
	ExternalReference: 'sr',
	FundKeyOrName: 'fnd',
	Language: 'lang',
	Notes: 'nt',
	AdditionalData: 'ad',
	Amount: 'a',
	AmountIsLocked: 'al',
	FundVisibility: 'fndv',
	RecurringSuggestion: 'rs',
	RecurringScheduleMigrationReturnUrlKey: 'rsm',
	AccountVisibility: 'av',
}
